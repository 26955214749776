import { db } from '@/firebase/config'

const getCTCompanies = async (store, careerTrack) => {
	try {
    await db.collection("Companies").orderBy("rank").get().then(docs => {
			docs.forEach(doc => {
				if(doc.data().careerTrack == careerTrack) {
					store.value.push({...doc.data(), id: doc.id})
				}
			})
		})
		await db.collection("Companies").where("careerTrack", "==", careerTrack).get().then(docs => {
			docs.forEach(doc => {
				if(!doc.data().rank){
					store.value.push({...doc.data(), id: doc.id})
				}
			})
		})
	} catch (error) {
		alert("getCTCompanies:" + error);
	}
}

export default getCTCompanies